import React from 'react'
import { Link } from 'react-router-dom'
import roomDt01 from '../../../assets/img/bg/gal-dt-01.jpg'
import roomDt02 from '../../../assets/img/bg/gal-dt-02.jpg'
import roomDt03 from '../../../assets/img/bg/gal-dt-03.jpg'
import roomDt04 from '../../../assets/img/bg/gal-dt-04.jpg'

function DeluxeTwinBeds() {
  return (
    <>
        <div className="about-area5 about-p p-relative">
                <div className="container pt-120 pb-40">
                    <div className="row">
					    <div className="col-sm-12 order-1">
                            <div className="service-detail">
                                <div className="two-column">
                                    <div className="row">
                                        <div className="image-column col-xl-4 col-lg-12 col-md-12">
                                            <figure className="image"><img src={roomDt01} alt=""/></figure>
                                        </div>
                                        <div className="text-column col-xl-4 col-lg-12 col-md-12">
                                            <figure className="image"><img src={roomDt02} alt=""/></figure>
                                            <figure className="image"><img src={roomDt03} alt=""/></figure>
                                        </div>      
                                        <div className="image-column col-xl-4 col-lg-12 col-md-12">
                                            <figure className="image"><img src={roomDt04} alt=""/></figure>
                                        </div>                             
                                    </div>
                                </div>
                                <div className="content-box">
                                    <div className="row align-items-center mb-20">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="price">
                                                <h2>Deluxe Twin Beds</h2>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 text-right">
                                            <img src="assets/img/testimonial/review-icon.png" alt="img"/>
                                        </div>
                                    </div>
                                    <div className="details">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-6 col-md-12">
                                                <ul>
                                                    <li>Baths : 1 With Bathroom Aminities</li>
                                                    <li>Pool View Or City View</li>
                                                    <li>Balcony</li>
                                                    <li>Hairdryer</li>
                                                    <li>Bath Slippers</li>
                                                </ul>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-12">
                                                <ul>
                                                    <li>Black – Out Curtains And Cheer Curtains</li>
                                                    <li>Complimentaty Drinking Water : 2 Bottles A Day</li>
                                                    <li>Complimentary Instant Coffee – Tea</li>
                                                    <li>Electric Kettle</li>
                                                    <li>Refrigerator</li>
                                                </ul>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-12">
                                                <ul>
                                                    <li>Safety Box In Room</li>
                                                    <li>Wide Angle Door Viewer</li>
                                                    <li>Key Card  System Door Lock</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <p></p>
                                    <h3>Room Feature.</h3>
                                    <ul className="room-features d-flex align-items-center">
                                        <li>
                                            <i className="fal fa-square"></i> Size : 30m²
                                        </li>
                                        <li>
                                            <i className="fal fa-user"></i> Guest Max : 2
                                        </li>
                                        <li>
                                            <i className="fal fa-bed"></i>Single Beds - 2
                                        </li>
                                        <li>
                                            <i className="fal fa-air-conditioner"></i> Air Condition
                                        </li>
                                        <li>
                                            <i className="fal fa-tv"></i> 40" HDTV
                                        </li>
                                        <li>
                                            <i className="fal fa-wifi"></i> Fasting Wi-Fi
                                        </li>
                                        <li>
                                            <i className="fal fa-fire"></i> Smoke Detector
                                        </li>
                                        <li>
                                            <i className="fal fa-phone-rotary"></i> Phone
                                        </li>
                                    </ul>
                                    <h3>More Rooms</h3>
                                    <div>
                                        <ul className="room-features d-flex align-items-center">
                                            <Link to="/room-details?type=junior-pool-view" className="btn ss-btn">Junior Pool View</Link>
                                            <Link to="/room-details?type=junior-sea-view" className="btn ss-btn">Junior Sea View</Link>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </>
  )
}

export default DeluxeTwinBeds