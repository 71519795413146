import React from 'react'
import { Link } from 'react-router-dom'
import Hotel1 from "../../assets/img/icon/fe-icon01.png"
import Hotel2 from "../../assets/img/icon/fe-icon04.png"
import Hotel3 from "../../assets/img/icon/fe-icon05.png"
// import Hotel4 from "../../assets/img/icon/fe-icon06.png"
import Hotel5 from "../../assets/img/icon/fe-icon07.png"
//import Hotel6 from "../../assets/img/icon/fe-icon08.png"
import FastWifi from "../../assets/img/icon/wifi-160-160.png"
import Pool from "../../assets/img/icon/pool-160-160.png"

function First() {
  return (
    <>
        <section id="service-details2" className="pt-120 pb-90 p-relative">
                <div className="animations-01"><img src="assets/img/bg/an-img-01.png" alt="an-img-01" /></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="services-08-item mb-30">
                                <div className="services-icon2">
                                    <img src={Hotel5} alt="img"/>
                                </div>
                                <div className="services-08-thumb">
                                    <img src={Hotel5} alt="img"/>
                                </div>
                                <div className="services-08-content">
                                    <h3><Link to="/service-details">Restaurants & Bars</Link></h3>
                                    <p>Enjoy our breakfast buffet and selection of a la carte in variety of menus</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="services-08-item mb-30">
                                <div className="services-icon2">
                                    <img src={Hotel1} alt="img"/>
                                </div>
                                <div className="services-08-thumb">
                                    <img src={Hotel1} alt="img"/>
                                </div>
                                <div className="services-08-content">
                                    <h3><Link to="/service-details">Daily House Keeping</Link></h3>
                                    <p>Clean By Quality Staff And Well Trained.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">                                  
                            <div className="services-08-item mb-30">
                                <div className="services-icon2">
                                    <img src={Hotel2} alt="img"/>
                                </div>
                                <div className="services-08-thumb">
                                    <img src={Hotel2} alt="img"/>
                                </div>
                                <div className="services-08-content">
                                    <h3><Link to="/service-details">The Beach</Link></h3>
                                    <p>Enjoy Serenity Beach Only 260 M. From The Hotel.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="services-08-item mb-30">
                                <div className="services-icon2">
                                    <img src={Hotel3} alt="img"/>
                                </div>
                                <div className="services-08-thumb">
                                    <img src={Hotel3} alt="img"/>
                                </div>
                                <div className="services-08-content">
                                    <h3><Link to="/service-details">Newly Built</Link></h3>
                                    <p>Enhanced with modern decor and full set amenities to provide outstanding quality service.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="services-08-item mb-30">
                                <div className="services-icon2">
                                    <img src={FastWifi} alt="img"/>
                                </div>
                                <div className="services-08-thumb">
                                    <img src={FastWifi} alt="img"/>
                                </div>
                                <div className="services-08-content">
                                    <h3><Link to="/service-details"> Fasting Wi-Fi</Link></h3>
                                    <p>Stay connected with high-speed WiFi available throughout the hotel</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="services-08-item mb-30">
                                <div className="services-icon2">
                                    <img src={Pool} alt="img"/>
                                </div>
                                <div className="services-08-thumb">
                                    <img src={Pool} alt="img"/>
                                </div>
                                <div className="services-08-content">
                                    <h3><Link to="/service-details">Swimming pool</Link></h3>
                                    <p>Awaken and refresh your senses at the swimming pool</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    </>
  )
}

export default First