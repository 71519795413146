import React from 'react'
import Bredcom from '../Bradcom/Main'
import First from '../Menpages/Menfaq/First'
import Newsletter from '../Menpages/Menfaq/Newsletter'
import Teem from '../Menpages/Menfaq/Teem'
import bgImg from '../../assets/img/bg/faq-bg.jpg'

const Main = () => {

  return (
    <>
        <main>
            <Bredcom title={"Faq"} subtitle={"Home"} newtitle={"Faq"} bgImg={bgImg} />
            <First/>
        </main>
    </>
  )
}

export default Main