import React , { useState } from 'react'
import icon1 from "../../assets/img/icon/sve-icon1.png"
import icon2 from "../../assets/img/icon/sve-icon2.png"
import icon3 from "../../assets/img/icon/sve-icon3.png"
import icon4 from "../../assets/img/icon/sve-icon4.png"
import icon5 from "../../assets/img/icon/sve-icon5.png"
import icon6 from "../../assets/img/icon/sve-icon6.png"
import roomCover1 from "../../assets/img/gallery/dt-cover.jpg"
import roomCover2 from "../../assets/img/gallery/jpv-cover.jpg"
import roomCover3 from "../../assets/img/gallery/jsv-cover.jpg"
import Slider from "react-slick"
import { Link } from 'react-router-dom'
import FsLightbox from 'fslightbox-react';


function Rooms() {

    const [toggler, setToggler] = useState(false);
    const [activeImage , setActiveImage] = useState(1)
    const [images , setImages] = useState([]);


    const service = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

  return (
    <>
        <section id="services" className="services-area pt-113 pb-150">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12">    
                            <div className="section-title center-align mb-50 text-center">
                                <h5>The pleasure of luxury</h5>
                                <h2>Rooms & Suites</h2>
                            </div>
                        </div>
                    </div>
                    <Slider className="row services-active" {...service}> 
                        <div className="col-xl-4 col-md-6">
                            <div className="single-services mb-30">
                                <div className="services-thumb">
									<Link to="#" className="gallery-link popup-image" onClick={() => { 
                                    setToggler(!toggler);
                                    setActiveImage(1);
                                    setImages([
                                        'assets/img/gallery/dt-01.jpg',
                                        'assets/img/gallery/dt-02.jpg',
                                        'assets/img/gallery/dt-03.jpg',
                                        'assets/img/gallery/dt-04.jpg',
                                        'assets/img/gallery/dt-05.jpg',
                                        'assets/img/gallery/dt-06.jpg',
                                        'assets/img/gallery/dt-07.jpg',
                                        'assets/img/gallery/dt-08.jpg',
                                        'assets/img/gallery/dt-09.jpg',
                                    ]);
                                }}>
                                    <img src={roomCover1} alt="img" />
									</Link>
                                </div>
                                <div className="services-content"> 
                                    <h4><Link to="/room-details">Deluxe Twin Beds</Link></h4>
                                    <ul>
                                        <li>Max 2 Guest</li>
                                        <li>Single Beds - 2</li>
                                        <li>1 Bath With Bathroom Amenities</li>
                                        <li>Pool View or City View</li>
                                    </ul>
                                    <div className="icon">
                                        <ul>
                                            <li><img src={icon1} alt="img"/></li>
                                            <li><img src={icon2} alt="img"/></li>
                                            <li><img src={icon3} alt="img"/></li>
                                            <li><img src={icon4} alt="img"/></li>
                                            <li><img src={icon5} alt="img"/></li>
                                            <li><img src={icon6} alt="img"/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="single-services mb-30">
                                <div className="services-thumb">
									<Link to="#" className="gallery-link popup-image" onClick={() => { 
                                    setToggler(!toggler);
                                    setActiveImage(1);
                                    setImages([
                                        'assets/img/gallery/jpv-01.jpg',
                                        'assets/img/gallery/jpv-02.jpg',
                                        'assets/img/gallery/jpv-03.jpg',
                                        'assets/img/gallery/jpv-04.jpg',
                                        'assets/img/gallery/jpv-05.jpg',
                                        'assets/img/gallery/jpv-06.jpg',
                                        'assets/img/gallery/jpv-07.jpg',
                                        'assets/img/gallery/jpv-08.jpg',
                                        'assets/img/gallery/jpv-09.jpg',
                                    ]);
                                }}>
                                    <img src={roomCover2} alt="img"/>
									</Link>
                                </div>
                                <div className="services-content"> 
                                    <h4><Link to="/room-details">Junior Pool View</Link></h4>
                                    <ul>
                                        <li>Max 3 Guest</li>
                                        <li>Single Beds - 3</li>
                                        <li>1 Bath With Bathroom Amenities</li>
                                        <li>Pool View</li>
                                    </ul>
                                    <div className="icon">
                                        <ul>
                                            <li><img src={icon1} alt="img"/></li>
                                            <li><img src={icon2} alt="img"/></li>
                                            <li><img src={icon3} alt="img"/></li>
                                            <li><img src={icon4} alt="img"/></li>
                                            <li><img src={icon5} alt="img"/></li>
                                            <li><img src={icon6} alt="img"/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="single-services mb-30">
                                <div className="services-thumb">
									<Link to="#" className="gallery-link popup-image" onClick={() => { 
                                    setToggler(!toggler);
                                    setActiveImage(1);
                                    setImages([
                                        'assets/img/gallery/jsv-01.jpg',
                                        'assets/img/gallery/jsv-02.jpg',
                                        'assets/img/gallery/jsv-03.jpg',
                                        'assets/img/gallery/jsv-04.jpg',
                                        'assets/img/gallery/jsv-05.jpg',
                                        'assets/img/gallery/jsv-06.jpg',
                                        'assets/img/gallery/jsv-07.jpg',
                                        'assets/img/gallery/jsv-08.jpg',
                                        'assets/img/gallery/jsv-09.jpg',
                                    ]);
                                }} >
                                    <img src={roomCover3} alt="img"/>
									</Link>
                                </div>
                                <div className="services-content"> 
                                    <h4><Link to="/room-details">Junior Sea View</Link></h4>
                                    <ul>
                                        <li>Max 3 Guest</li>
                                        <li>King Bed 1 / Single Bed - 1</li>
                                        <li>1 Bath With Bathroom Amenities</li>
                                        <li>Sea View</li>
                                    </ul>
                                    <div className="icon">
                                        <ul>
                                            <li><img src={icon1} alt="img"/></li>
                                            <li><img src={icon2} alt="img"/></li>
                                            <li><img src={icon3} alt="img"/></li>
                                            <li><img src={icon4} alt="img"/></li>
                                            <li><img src={icon5} alt="img"/></li>
                                            <li><img src={icon6} alt="img"/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
        </section>

        <FsLightbox
            toggler={toggler}
            sources={images}
            slide={activeImage}
        />
    </>
  )
}

export default Rooms