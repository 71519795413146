import React from 'react'
import { Link } from 'react-router-dom'
import roomJsv01 from '../../../assets/img/bg/gal-jsv-01.jpg'
import roomJsv02 from '../../../assets/img/bg/gal-jsv-02.jpg'
import roomJsv03 from '../../../assets/img/bg/gal-jsv-03.jpg'
import roomJsv04 from '../../../assets/img/bg/gal-jsv-04.jpg'

function JuniorSeaView() {
  return (
    <>
        <div className="about-area5 about-p p-relative">
                <div className="container pt-120 pb-40">
                    <div className="row">
					    <div className="col-sm-12 order-1">
                            <div className="service-detail">
                                <div className="two-column">
                                    <div className="row">
                                        <div className="image-column col-xl-4 col-lg-12 col-md-12">
                                            <figure className="image"><img src={roomJsv01} alt=""/></figure>
                                        </div>
                                        <div className="text-column col-xl-4 col-lg-12 col-md-12">
                                            <figure className="image"><img src={roomJsv02} alt=""/></figure>
                                            <figure className="image"><img src={roomJsv03} alt=""/></figure>
                                        </div>      
                                        <div className="image-column col-xl-4 col-lg-12 col-md-12">
                                            <figure className="image"><img src={roomJsv04} alt=""/></figure>
                                        </div>                             
                                    </div>
                                </div>
                                <div className="content-box">
                                    <div className="row align-items-center mb-20">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="price">
                                                <h2>Junior Sea View</h2>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 text-right">
                                            <img src="assets/img/testimonial/review-icon.png" alt="img"/>
                                        </div>
                                    </div>
                                    <div className="details">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-6 col-md-12">
                                                <ul>
                                                    <li>Baths : 1 With Bathroom Aminities</li>
                                                    <li>Sea View</li>
                                                    <li>Sofa</li>
                                                    <li>Balcony With 2 Chairs - 1 Table</li>
                                                    <li>Hairdryer</li>
                                                </ul>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-12">
                                                <ul>
                                                    <li>Bathrobe</li>
                                                    <li>Bath Slippers</li>
                                                    <li>Black – Out Curtains And Cheer Curtains</li>
                                                    <li>Complimentaty Drinking Water : 3 Bottles A Day</li>
                                                    <li>Complimentary Instant Coffee – Tea</li>
                                                </ul>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-12">
                                                <ul>
                                                    <li>Electric Kettle</li>
                                                    <li>Refrigerator</li>
                                                    <li>Safety Box In Room</li>
                                                    <li>Wide Angle Door Viewer</li>
                                                    <li>Key Card  System Door Lock</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <p></p>
                                    <h3>Room Feature.</h3>
                                    <ul className="room-features d-flex align-items-center">
                                        <li>
                                            <i className="fal fa-square"></i> Size : 45m²
                                        </li>
                                        <li>
                                            <i className="fal fa-user"></i> Guest Max : 3
                                        </li>
                                        <li>
                                            <i className="fal fa-bed"></i>King Bed - 1 / Single Bed - 1
                                        </li>
                                        <li>
                                            <i className="fal fa-air-conditioner"></i> Air Condition
                                        </li>
                                        <li>
                                            <i className="fal fa-tv"></i> 40" HDTV
                                        </li>
                                        <li>
                                            <i className="fal fa-wifi"></i> Fasting Wi-Fi
                                        </li>
                                        <li>
                                            <i className="fal fa-fire"></i> Smoke Detector
                                        </li>
                                        <li>
                                            <i className="fal fa-phone-rotary"></i> Phone
                                        </li>
                                    </ul>
                                    <h3>More Rooms</h3>
                                    <div>
                                        <ul className="room-features d-flex align-items-center">
                                            <Link to="/room-details?type=deluxe-twin-bed" className="btn ss-btn">Deluxe Twin Beds</Link>
                                            <Link to="/room-details?type=junior-pool-view" className="btn ss-btn">Junior Pool View</Link>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </>
  )
}

export default JuniorSeaView