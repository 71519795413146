import React, { useEffect, useState } from 'react';
import Bredcom from '../../Bradcom/Main';
import RoomDeluxeTwinBed from '../../Menrooms/Menroomdeatils/deluxe_twin_beds';
import JuniorPoolView from '../../Menrooms/Menroomdeatils/junior_pool_view';
import JuniorSeaView from '../../Menrooms/Menroomdeatils/junior_sea_view';
import RoomBgDeluxeTwinBed from "../../../assets/img/bg/room-dt-bg.jpg";
import RoomBgJuniorPoolView from "../../../assets/img/bg/room-jpv-bg.jpg";
import RoomBgJuniorSeaView from "../../../assets/img/bg/room-jsv-bg.jpg";

const Main = () => {
  const [currentPage, setCurrentPage] = useState('')
  const roomType = (window.location && new URLSearchParams(window.location.search).get('type'));

  useEffect(() => {
    if (roomType !== currentPage) {
      setCurrentPage(roomType);
      window.scrollTo(0, 480)
    }
  }, [currentPage, roomType]);

  const BgImg = () => {
    if (roomType === 'deluxe-twin-bed') {
      return RoomBgDeluxeTwinBed;
    } else if (roomType === 'junior-pool-view') {
      return RoomBgJuniorPoolView;
    } else if (roomType === 'junior-sea-view') {
      return RoomBgJuniorSeaView;
    }

    return RoomBgJuniorSeaView;
  }

  return (
    <>
        <main>
            <Bredcom title={"Room Deatils"} subtitle={"Home"} newtitle={"Room Details"} bgImg={BgImg()} />
            {roomType === 'deluxe-twin-bed' && (
              <RoomDeluxeTwinBed />
            )}
            {roomType === 'junior-pool-view' && (
              <JuniorPoolView />
            )}
            {(roomType === 'junior-sea-view' || !roomType) && (
              <JuniorSeaView />
            )}
        </main>
    </>
  )
}

export default Main