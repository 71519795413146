import React from 'react'
import { Link } from 'react-router-dom'
import BGImg from "../../assets/img/bg/footer-bg.png";

const Main = () => {
  return (
    <>
        <footer className="footer-bg footer-p">
            <div className="footer-top  pt-90 pb-40" style={{background_color: "#644222", backgroundImage: `url(${BGImg})`}}>
                <div className="container">
                    <div className="row justify-content-between">                          
                        <div className="col-xl-4 col-lg-4 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title mb-30">
                                   <img src="assets/img/logo/lhc_logo.png" alt="img" />
                                </div>
                                <div className="f-contact">
                                    <ul>
                                        <li>
                                            <i className="icon fal fa-phone"></i>
                                            <span>+66-33-001998<br/>+66-98-2895787</span>
                                        </li>
                                        <li>
                                            <i className="icon fal fa-envelope"></i>
                                            <span>
                                                <Link to="mailto: rsvn.lhchotelandresort@gmail.com">rsvn.lhchotelandresort@gmail.com</Link><br/>
                                            </span>
                                        </li>
                                        <li>
                                            <i className="icon fal fa-map-marker-check"></i>
                                            <span>284/89 Moo.5 Naklua, Banglamung<br/>Chonburi 20150 Thailand</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
						<div className="col-xl-2 col-lg-2 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                    <h2>Our Links</h2>
                                </div>
                                <div className="footer-link">
                                    <ul>                                        
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/about">About Us</Link></li>
                                        <li><Link to="/room">Our Rooms</Link></li>
                                        <li><Link to="/amenities">Amenities</Link></li>
                                        <li><Link to="/contact">Contact</Link></li>
                                        <li><Link to="/faq">FAQ</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div> 
                        <div className="col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                  <h2>Subscribe To Our Newsletter</h2>
                                </div>
                                <div className="footer-link">
                                <p>Subscribe our newsletter to get our latest update &amp; News</p>
                                    <div className="subricbe p-relative" data-animation="fadeInDown" data-delay=".4s" >
                                        <form action="#" method="post" className="contact-form ">
                                            <input type="text" id="email2" name="email2"  className="header-input" placeholder="Your Email..." required />
                                            <button className="btn header-btn"> <i className="fas fa-location-arrow"></i> </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-wrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">                         
                           Copyright &copy; LHC Hotel & Resort 2023 . All rights reserved.         
                        </div>
                        <div className="col-lg-6 col-md-6 text-right text-xl-right">                       
                           <div className="footer-social">                                    
                                <Link to="https://www.facebook.com/Lhchotelandresort" title="Facebook" target="_blank"><i className="fab fa-facebook-f"></i></Link>
                                <Link to="http://line.me/ti/p/~@lhchotel" title="@lhchotel" target="_blank"><i className="fab fa-line"></i></Link>
                            </div>        
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>
  )
}

export default Main
