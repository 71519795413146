import React from 'react'
import { Link } from 'react-router-dom'
import About from "../../assets/img/bg/an-img-02.png"
import About1 from "../../assets/img/features/about_view.jpg"
import About2 from "../../assets/img/features/about_lhc.jpg"

function Most() {
  return (
    <>
        <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02"><img src={About} alt="contact-bg-an-02"/></div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                <img src={About1} alt="img"/>   
                                <div className="about-icon">
                                    <img src={About2} alt="img"/>   
                                </div>
                            </div>
                        </div>
					    <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                                <div className="about-title second-title pb-25">  
                                    <h5>About Us</h5>
                                    <h2>Enjoy your days at LHC HOTEL&RESORT</h2>                                   
                                </div>
                                <p>Beautifully nestled in the serene district of Naklua, LHC Hotel & Resort offers guest a relaxing retreat from the rapid pace of the city.</p>
                                <p>The newly remodeled rooms are uniquely designed, revealing peaceful harbor views, gazed upon from private balconies.</p>
                                <p>A stay with LHC Hotel & Resort is a drift away from stress and a gateway to enjoy all the charms Naklua provides.</p>
                                <p>The hotel offers  82 splendid rooms that include Deluxe, Junior Suite Sea View, Junior Suite Pool View, and Premium Jacuzzi Suite.</p>
                                <p>enhanced with modern decor and full set amenities to provide outstanding quality service coated with exceptional Thai hospitality.</p>
                                <div className="about-content3 mt-30">
                                    <div className="row justify-content-center align-items-center">
                                    <div className="col-md-6">
                                        <div className="slider-btn">                                          
                                            <Link to="/about" className="btn ss-btn smoth-scroll">Discover More</Link>				
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    </>
  )
}

export default Most