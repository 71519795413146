import React from 'react'
import Bredcom from '../../Bradcom/Main'
import First from '../../Menservice/First';
import Luxury from '../../Menhome/Luxury'
import Book from '../../Menhome/Book';
import Testimonial from '../../Menhome/Testimonial';
import bgImg from "../../../assets/img/bg/restaurant-bg.jpg";

const Main = () => {

  return (
    <>
        <main>
           <Bredcom title={"Services"} subtitle={"Home"} newtitle={"Services"} bgImg={bgImg} />
           <First/>
           {/*
           <Luxury/>
           <Book/>
           <Testimonial/>
           */}
        </main>
    </>
  )
}

export default Main