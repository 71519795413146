import React from 'react'

function First() {
  return (
    <>
        <section id="faq" className="faq-area pt-50 pb-50">             
            <div className="container">   
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="faq-wrap">
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h2 className="mb-0">
                                            <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                                                <i class="fal fa-child fa-lg"></i>&nbsp;&nbsp;Child Policies
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="card-body">
                                            Children Of All Ages  Are Welcome 
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h2 className="mb-0">
                                            <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse"  data-bs-target="#collapseTwo">
                                                <i class="fal fa-door-open fa-lg"></i>&nbsp;&nbsp;No Age  Restriction
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="card-body">
                                            There’s No Age Requirement For Check-in 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="faq-wrap">
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                                            <i class="fal fa-dog fa-lg"></i>&nbsp;&nbsp;Pets
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" className="collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample" >
                                        <div className="card-body">
                                            Pets Are Not Allowed
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                                            <i class="fal fa-clock fa-lg"></i>&nbsp;&nbsp;Check-In : Check-Out
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" className="collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample" >
                                        <div className="card-body">
                                            Check-In : 14.00 Hrs. and Check-Out 12.00 Hrs.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default First