import React from 'react'
import Bredcom from '../../Bradcom/Main'
import First from '../../Menservishdeatils/First';

const Main = () => {
  return (
    <>
        <main>
            <Bredcom title={"Services Deatils"} subtitle={"Home"} newtitle={"Services Deatils"} />
            <First/>
        </main>
    </>
  )
}

export default Main