import React from 'react'
import Bredcom from '../../Bradcom/Main'
import First from '../../Menrooms/Menourrooms/First';
import Luxury from '../../Menhome/Luxury';
import Book from '../../Menhome/Book';
import Take from '../../Menhome/Take';
import Rooms from '../../Menhome/Rooms';
import bgImg from "../../../assets/img/bg/room-bg.jpg";

const Main = () => {

  return (
    <>
        <main>
           <Bredcom title={"Our Rooms"} subtitle={"Home"} newtitle={"Our Rooms"} bgImg={bgImg}/>
           <First/>
           {/*
           <Luxury/>
           <Book/>
           <Take/>
           */}
        </main>
    </>
  )
}

export default Main