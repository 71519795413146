import React, { useState } from "react";


function News() {
    const [email, setEmail] = useState("");

    const handleSumbit = (e) => {
        // console.log("Ankit");
        const url = "http://localhost:3000/modal/newsletter.php";
        let fData = new FormData();
        fData.append("email", email);
        // axios
        //     .post(url, fData)
        //     .then((response) => {
        //         setFirstname("");
        //         setEmail("");
        //         setWritecomment("");
        //         setPhone("");
        //         setSubjet("");
        //     })
        //     .catch((error) => { });

        setEmail('');
    };

    return (
        <>
            <section className="newslater-area p-relative pt-120 pb-120" style={{ background: "#f7f5f1" }}>
                <div className="animations-01"><img src="assets/img/bg/an-img-07.png" alt="contact-bg-an-05" /></div>
                <div className="container">
                    <div className="row justify-content-center align-items-center text-center">
                        <div className="col-xl-9 col-lg-9">
                            <div className="section-title center-align mb-40 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                                <h5>Newsletter</h5>
                                <h2>
                                    Get Best Offers On The Hotel
                                </h2>
                                <p>With the subscription, enjoy your favourite Hotels without having to think about it</p>
                            </div>
                            <form name="ajax-form" id="contact-form4" className="contact-form newslater" onSubmit={handleSumbit}>
                                <div className="form-group">
                                    <input className="form-control" id="email3" name="email" type="email" placeholder="Your Email Address" value={email} onChange={(e)=>{setEmail(e.target.value)}} required="" />
                                    <button type="submit" className="btn btn-custom" id="send2">Subscribe Now</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default News