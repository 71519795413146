import { Link } from 'react-router-dom'
import React from 'react'
import roomCover1 from "../../../assets/img/gallery/dt-cover.jpg"
import roomCover2 from "../../../assets/img/gallery/jpv-cover.jpg"
import roomCover3 from "../../../assets/img/gallery/jsv-cover.jpg"

function First() {
    
    return (
        <>
            <section id="services" className="services-area pt-120 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-md-6">
                            <div className="single-services ser-m mb-30">
                                <div className="services-thumb">
                                    <Link to="/room-details?type=deluxe-twin-bed" className="gallery-link popup-image" >
                                        <img src={roomCover1} alt="img" />
                                    </Link>
                                </div>
                                <div className="services-content">
                                    {/*
                                    <div className="day-book">
                                        <ul>
                                            <li>999/Night</li>
                                            <li><Link to="/contact">Book Now</Link></li>
                                        </ul>
                                    </div>
                                    */}
                                    <h4><Link to="/room-details?type=deluxe-twin-bed">Deluxe Twin Bed</Link></h4>
                                    <ul>
                                        <li>Max 2 Guest</li>
                                        <li>Single Beds - 2</li>
                                        <li>1 Bath With Bathroom Amenities</li>
                                        <li>Pool View or City View</li>
                                    </ul>
                                    <div className="icon">
                                        <ul>
                                            <li><img src="assets/img/icon/sve-icon1.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon2.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon3.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon4.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon5.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon6.png" alt="img" /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="single-services ser-m mb-30">
                                <div className="services-thumb">
                                    <Link to="/room-details?type=junior-pool-view" className="gallery-link popup-image" >
                                        <img src={roomCover2} alt="img" />
                                    </Link>
                                </div>
                                <div className="services-content">
                                    {/*
                                    <div className="day-book">
                                        <ul>
                                            <li>฿1500/Night</li>
                                            <li><Link to="/contact">Book Now</Link></li>
                                        </ul>
                                    </div>
                                    */}
                                    <h4><Link to="/room-details?type=junior-pool-view">Junior Pool View</Link></h4>
                                    <ul>
                                        <li>Max 3 Guest</li>
                                        <li>Single Beds - 3</li>
                                        <li>1 Bath With Bathroom Amenities</li>
                                        <li>Pool View</li>
                                    </ul>
                                    <div className="icon">
                                        <ul>
                                            <li><img src="assets/img/icon/sve-icon1.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon2.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon3.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon4.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon5.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon6.png" alt="img" /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="single-services ser-m mb-30">
                                <div className="services-thumb">
                                    <Link to="/room-details?type=junior-sea-view" className="gallery-link popup-image" >
                                        <img src={roomCover3} alt="img" />
                                    </Link>
                                </div>
                                <div className="services-content">
                                    {/*
                                    <div className="day-book">
                                        <ul>
                                            <li>฿2000/Night</li>
                                            <li><Link to="/contact">Book Now</Link></li>
                                        </ul>
                                    </div>
                                    */}
                                    <h4><Link to="/room-details?type=junior-sea-view">Junior Sea View</Link></h4>
                                    <ul>
                                        <li>Max 3 Guest</li>
                                        <li>King Bed 1 / Single Bed - 1</li>
                                        <li>1 Bath With Bathroom Amenities</li>
                                        <li>Sea View</li>
                                    </ul>
                                    <div className="icon">
                                        <ul>
                                            <li><img src="assets/img/icon/sve-icon1.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon2.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon3.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon4.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon5.png" alt="img" /></li>
                                            <li><img src="assets/img/icon/sve-icon6.png" alt="img" /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First